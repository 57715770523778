/**
 * Small, reusable functional components
 *
 * The components are registered in bootstrap.js
 *
 * Note: Some of these components depend on styles defined in main.styl
 */
import Vue from 'vue'
import colors from 'vuetify/es5/util/colors'
import { useEventStore } from '@/stores/event'


import StatusIcon from './StatusIcon.js'

const COLORS = Object.keys(colors).filter(e => {
  // Remove unwanted colors
  const forbidden = ['shades']
  return !forbidden.includes(e)
}).map(e => {
  // camelCase to dash-case (dash-case is used in CSS)
  return e.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
})


function hashCode(txt) {
  let hash = 0
  const len = txt.length
  let i = 0
  while (i < len) {
    hash = ((hash << 5) - hash + txt.charCodeAt(i++)) << 0
  }
  return hash
}

function getColor(txt) {
  return COLORS[Math.abs(hashCode(txt)) % COLORS.length]
}

export const TextIcon = {
  functional: true,
  render: function (createElement, { props, data, children }) {
    const txt = children[0].text.trim()
    let color = ''
    if (props.colorize !== undefined) {
      color = 'white--text darken-2 ' + getColor(txt)
    }

    data.staticClass = ['text-icon', color, data.staticClass].join(' ')
    return createElement('span', data, txt[0])
  },
}

export const MailLink = {
  render: function (createElement) {
    return createElement('a', {
      attrs: {
        href: 'mailto:' + this.$slots.default[0].text,
      },
      on: {
        click: (e) => e.stopPropagation(),
      },
    },
    this.$slots.default,
    )
  },
}

export const PhoneLink = {
  render: function (createElement) {
    return createElement('a', {
      attrs: {
        href: 'tel:' + this.$slots.default[0].text.replace(/ /g, ''),
      },
      on: {
        click: (e) => e.stopPropagation(),
      },
    },
    this.$slots.default,
    )
  },
}


export const OrgIcon = {
  functional: true,
  props: {
    organisation: {
      default: '',
      type: String,
    },
  },
  render: function (createElement, { props, data, children }) {
    const org = props.organisation
    let text = '??'
    let color = 'blue-grey white--text'
    const store = useEventStore()
    const orgDef = store.meta.organisations.find((e) => e.code === org)
    if(orgDef !== undefined) {
      text = orgDef.symbol || text
      color = orgDef.color || color
    }

    data.staticClass = ['text-icon', color, data.staticClass].join(' ')
    return createElement('span', data, text)
  },
}


Vue.component('text-icon', TextIcon)
Vue.component('mail-link', MailLink)
Vue.component('phone-link', PhoneLink)
Vue.component('org-icon', OrgIcon)

export default {
  PhoneLink, MailLink, TextIcon, OrgIcon, StatusIcon,
}
